import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  CloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import { PropsWithChildren } from 'react'

const Toast: React.FC<PropsWithChildren> = ({ children }) => {
  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true })
  return isVisible ? (
    <Alert status="success">
      <AlertIcon />
      <Box>
        <AlertDescription>{children}</AlertDescription>
      </Box>
      <CloseButton
        alignSelf="flex-start"
        position="relative"
        right={-1}
        top={-1}
        onClick={onClose}
      />
    </Alert>
  ) : (
    <></>
  )
}

export default Toast
