const styles = {
  global: {
    body: {
      bg: 'chakra-body-bg',
      color: 'chakra-body-text',
      fontSize: 'md',
    },
  },
}

export default styles
