import { RouteObject, useRoutes } from 'react-router-dom'
import RequireAuth from '../../../context/RequireAuth'
import Login from '../../../pages/Login/Login'
import ProductListPage from '../../../pages/ProductListPage/ProductListPage'
import ProductDetails from '../../ProductDetails/ProductDetails'

const AppRoutes: RouteObject[] = [
  { path: '*', element: <Login /> },
  {
    path: '/',
    element: (
      <RequireAuth>
        <ProductListPage/>
      </RequireAuth>
    ),
  },
  {
    path: `/products/:productID`,
    element: (
      <RequireAuth>
        <ProductDetails />
      </RequireAuth>
    )
  },
  { path: '/login', element: <Login /> },
]

const Routing: React.FC = () => {
  const routesToRender = useRoutes(AppRoutes)
  return <>{routesToRender}</>
}

export default Routing
