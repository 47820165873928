import { Navigate } from 'react-router-dom'
import useAuth from './AuthContext'

const RequireAuth = ({ children }: any) => {
  const { isLoggedIn, loading } = useAuth()
  return loading ? (
    `loading ...`
  ) : isLoggedIn ? (
    children
  ) : (
    <Navigate to={`/login`} replace />
  )
}

export default RequireAuth
