import logo from '../../assets/images/oc_logo.svg'
import logoDark from '../../assets/images/oc_logo--dark.svg'
import { NavLink, useNavigate } from 'react-router-dom'
import {
  Box,
  Flex,
  HStack,
  Link,
  Image,
  Menu,
  MenuButton,
  Avatar,
  MenuList,
  MenuItem,
  Button,
  Tooltip,
  useColorMode,
  IconButton,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react'
import useAuth from '../../context/AuthContext'
import { mdiEmoticonCoolOutline } from '@mdi/js'

const TopNavigation = (props: any) => {
  const { isLoggedIn, user, logout } = useAuth()
  const { toggleColorMode } = useColorMode()
  const navigate = useNavigate()
  const handleLogout = () => {
    logout()
    navigate(`/login`)
  }
  return (
    <Box
      px={4}
      position="sticky"
      top="0"
      borderBottom={'1px'}
      borderColor={'blackAlpha.200'}
      bg={useColorModeValue('white', 'whiteAlpha.300')}
      zIndex={1}
    >
      <Flex h={14} alignItems={'center'} justifyContent={'space-between'}>
        <HStack spacing={8} alignItems={'center'}>
          <Image
            width="125px"
            height="auto"
            src={useColorModeValue(logo, logoDark)}
            alt="Ordercloud Logo"
          />
          <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
            {isLoggedIn &&
              NAV_ITEMS.map((item, idx) => (
                <Button
                  as={NavLink}
                  to={item.route}
                  variant="ghost"
                  key={idx}
                  _activeLink={{ bg: 'primary.100' }}
                >
                  {item.label}
                </Button>
              ))}
          </HStack>
        </HStack>
        <Tooltip label="Dark Mode">
          <IconButton
            ml="auto"
            mr="2"
            size="sm"
            onClick={toggleColorMode}
            aria-label="this is required"
            variant="subtle"
            color={useColorModeValue('blackAlpha.500', 'whiteAlpha.400')}
            icon={
              <Icon boxSize="6">
                <path fill="currentColor" d={mdiEmoticonCoolOutline} />
              </Icon>
            }
          />
        </Tooltip>
        <Flex alignItems={'center'}>
          {user ? (
            <Menu>
              <MenuButton
                as={Button}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}
              >
                <Avatar
                  size={'sm'}
                  name={`${user?.FirstName} ${user?.LastName}`}
                />
              </MenuButton>
              <MenuList>
                <MenuItem as={NavLink} to="/profile">
                  My account
                </MenuItem>
                <MenuItem onClick={handleLogout}>Log out</MenuItem>
              </MenuList>
            </Menu>
          ) : (
            <Link
              as={NavLink}
              to={`/login`}
              px={3}
              py={2}
              rounded={'md'}
              color={'blackAlpha.700'}
              fontWeight={'semibold'}
              fontSize={'14px'}
              _hover={{
                textDecoration: 'none',
                bg: 'purple.100',
                color: 'purple.700',
              }}
            >
              Login
            </Link>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

export default TopNavigation

export interface NavItem {
  label: string
  route: string
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: 'Shop',
    route: '/',
  },
]
