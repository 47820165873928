import { Box, Flex, Grid, Text } from "@chakra-ui/react";
import { BuyerProduct, ListPage, Me } from "ordercloud-javascript-sdk";
import { useEffect, useState } from "react";
import ProductCard from "../../components/ProductCard/ProductCard";
const ProductListPage = () => {
  const [products, setProducts] = useState<ListPage<BuyerProduct> | undefined>(
    undefined
  );
  useEffect(() => {
    (async () => {
      const meProducts = await Me.ListProducts();
      if (meProducts) setProducts(meProducts);
    })();
  }, []);
  return (
    <>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          padding: 120,
          fontSize: 40,
          fontWeight: "bold",
        }}
      >
        Welcome to Sierra Trading Company
      </Box>
      <Box marginLeft={10} marginRight={10}>
        <Text fontSize={28}>Recent products</Text>
        <Flex
          direction="column"
          justifyContent="center"
          maxW={{ xl: "1200px" }}
          m="0 auto"
          minH="100vh"
        >
          <Grid
            w="full"
            gridGap="5"
            gridTemplateColumns="repeat( auto-fit, minmax(300px, 1fr) )"
          >
            {products?.Items?.map((p, idx) => (
              <ProductCard key={idx} product={p} />
            ))}
          </Grid>
        </Flex>
      </Box>
    </>
  );
};

export default ProductListPage;
