// Ready to create new overrides?
// After editing the component file, uncomment the import and component property.
// https://github.com/chakra-ui/chakra-ui/tree/main/packages/theme/src/components

//-------------------------------------------------------------------------------

// import Accordion from "./accordion"
import Alert from "./alert";
// import Avatar from "./avatar"
import Badge from "./badge";
import Breadcrumb from "./breadcrumb";
import Button from "./button";
import Checkbox from "./checkbox";
import CloseButton from "./close-button";
import Code from "./code";
// import Container from "./container"
import Divider from "./divider";
// import Drawer from "./drawer"
// import Editable from "./editable"
import Form from "./form"
// import FormError from "./form-error"
// import FormLabel from "./form-label"
import Heading from "./heading";
import Input from "./input";
// import Kbd from "./kbd"
import Link from "./link";
// import List from "./list"
// import Menu from "./menu"
import Modal from "./modal";
import NumberInput from "./number-input";
// import PinInput from "./pin-input"
// import Popover from "./popover"
// import Progress from "./progress"
import Radio from "./radio";
import Select from "./select";
import Skeleton from "./skeleton";
// import SkipLink from "./skip-link"
import Slider from "./slider";
import Spinner from "./spinner";
// import Stat from "./stat"
import Switch from "./switch";
import Table from "./table";
import Tabs from "./tabs";
import Tag from "./tag";
import Textarea from "./textarea";
// import Tooltip from "./tooltip"

const components = {
    // Accordion,
    Alert,
    // Avatar,
    Badge,
    Breadcrumb,
    Button,
    Checkbox,
    CloseButton,
    Code,
    // Container,
    Divider,
    // Drawer,
    // Editable,
    Form,
    // FormError,
    // FormLabel,
    Heading,
    Input,
    // Kbd,
    Link,
    // List,
    // Menu,
    Modal,
    NumberInput,
    // PinInput,
    // Popover,
    // Progress,
    Radio,
    Select,
    Skeleton,
    // SkipLink,
    Slider,
    Spinner,
    // Stat,
    Switch,
    Table,
    Tabs,
    Tag,
    Textarea,
    // Tooltip,
}

export default components