const Table = {
  /**
   * TODO: Eric, I noticed that even using mode() with blackAlpha.100 / whiteAlpha.200
   * that the resulting styles weren't any different than the default color assignments
   * so I just removed them here as an example. Also changed th fontsize to 'md'... we
   * can switch that back if it's incorrect - but it definitely felt a little small.
   * */
  baseStyle: {
    tr: {
      th: {
        fontSize: 'sm',
        textTransform: 'none',
        letterSpacing: 'normal',
        fontWeight: 'semibold',
      },
      '&[href]:hover': {
        td: {
          backgroundColor: 'blackAlpha.50',
        },
      },
    },
  },
  variants: {
    striped: {
      tr: {
        th: {
          borderColor: 'none',
        },
        td: {
          borderColor: 'none',
        },
      },
    },
  },
}

export default Table
