const Breadcrumb = {
  baseStyle: {
    link: {
      color: "chakra-placeholder-color",
    },
    separator: {
      color: "border-color-a11y",
    },
  },
};

export default Breadcrumb;
