const Badge = {
  baseStyle: {
    fontSize: "sm",
  },
  defaultProps: {
    colorScheme: "blackAlpha",
  },
};

export default Badge;
