import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Configuration } from 'ordercloud-javascript-sdk';
import { BrowserRouter } from 'react-router-dom';
import { init, setCredentials } from '@sitecore-discover/react';

Configuration.Set({
  baseApiUrl: 'https://sandboxapi.ordercloud.io',
  apiVersion: 'v1',
})

setCredentials({
  env: 'prod',
  customerKey: process.env.REACT_APP_RFK_CUSTOMER_KEY,
  apiKey: process.env.REACT_APP_RFK_API_KEY,
  useToken: true,
});
init(); 

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
