import { Box, Heading, Image, Stack, Text } from "@chakra-ui/react";
import { BuyerProduct } from "ordercloud-javascript-sdk";
import { Link } from "react-router-dom";
import formatter from "../../utils/currency.util";

interface ProductCardProps {
  product: BuyerProduct;
}
const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const p = product;
  const IMAGE = (p?.xp as any)?.imageUrl;
  return (
    <Link to={`/products/${p?.ID}`} state={p}>
      <Stack p={{ base: "0 2rem" }}>
        <Image
          maxHeight="280px"
          objectFit="contain"
          src={IMAGE}
          alt={"product img"}
        />
        <Text color="teal.600" textTransform="uppercase">
          SKU {p?.ID}
        </Text>

        <Heading color="teal.300" size="md" textTransform="capitalize">
          {p?.Name}
        </Heading>
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <Text fontWeight={800} fontSize={"xl"} marginRight={2}>
            {p?.PriceSchedule?.PriceBreaks?.[0]?.SalePrice
              ? formatter.format(p?.PriceSchedule?.PriceBreaks?.[0]?.SalePrice)
              : formatter.format(p?.PriceSchedule?.PriceBreaks?.[0]?.Price!)}
          </Text>
          {p?.PriceSchedule?.PriceBreaks?.[0]?.SalePrice && (
            <Text textDecoration={"line-through"} color={"gray.600"}>
              {formatter.format(p?.PriceSchedule?.PriceBreaks?.[0]?.Price!)}
            </Text>
          )}
        </Box>
      </Stack>
    </Link>
  );
};
export default ProductCard;
