const Switch = {
  baseStyle: {
    track: {
      bg: "border-color-a11y",
    },
  },
  defaultProps: {
    colorScheme: "teal",
  },
};
export default Switch;
