const CloseButton = {
  baseStyle: {
    rounded: "full",
  },
  sizes: {
    lg: {
      fontSize: "md",
    },
  },
};

export default CloseButton;
