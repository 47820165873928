const semanticTokens = {
  colors: {
    /* THESE ARE NOT WORKING FOR SOME REASON */
    'chakra-body-text': {
      default: 'red.500',
      _dark: 'whiteAlpha.900',
    },
    'chakra-label-text': {
      default: 'blackAlpha.400',
      _dark: 'whiteAlpha.400',
    },
    'chakra-body-bg': {
      default: 'cyan.200',
      _dark: 'gray.800',
    },
    'chakra-border-color': {
      default: 'blackAlpha.200',
      _dark: 'whiteAlpha.300',
    },
    'panel-background': {
      default: 'blackAlpha.50',
      _dark: 'whiteAlpha.50',
    },
    //TODO: Eric! I added this to be used on left drawer and "panel" boxes rendered inside MainContent
    'primary-color': {
      default: 'primary.500',
      _dark: 'primary.300',
    },
    'border-color-subtle': {
      default: 'blackAlpha.100',
      _dark: 'whiteAlpha.200',
    },
    'border-color-a11y': {
      default: 'blackAlpha.400',
      _dark: 'whiteAlpha.400',
    },
    'border-color-a11y-hover': {
      default: 'blackAlpha.600',
      _dark: 'whiteAlpha.500',
    },
  },
}

export default semanticTokens
