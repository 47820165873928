const basePalette = {
  colors: {
    gray: {
      50: "#fafafa",
      100: "#f4f4f5",
      200: "#e4e4e7",
      300: "#d4d4d8",
      400: "#94949D" /* adjusted for 3:1 contrast ratio */,
      500: "#71717a",
      600: "#52525b",
      700: "#3f3f46",
      800: "#27272a",
      900: "#18181b",
    },
    blackAlpha: {
      50: "rgba(0,0,0,0.02)",
      100: "rgba(0,0,0,0.04)",
      200: "rgba(0,0,0,0.10)",
      300: "rgba(0,0,0,0.17)",
      400: "rgba(0,0,0,0.42)" /* adjusted for 3:1 contrast ratio */,
      500: "rgba(0,0,0,0.55)",
      600: "rgba(0,0,0,0.68)",
      700: "rgba(0,0,0,0.75)",
      800: "rgba(0,0,0,0.85)",
      900: "rgba(0,0,0,0.91)",
    },
    whiteAlpha: {
      50: "rgba(255,255,255,0.02)",
      100: "rgba(255,255,255,0.04)",
      200: "rgba(255,255,255,0.10)",
      300: "rgba(255,255,255,0.17)",
      400: "rgba(255,255,255,0.42)",
      500: "rgba(255,255,255,0.55)",
      600: "rgba(255,255,255,0.68)",
      700: "rgba(255,255,255,0.75)",
      800: "rgba(255,255,255,0.85)",
      900: "rgba(255,255,255,0.91)",
    },
    red: {
      50: "#fff5f5",
      100: "#ffe6e6",
      200: "#feb2b2",
      300: "#fc8181",
      400: "#f56565",
      500: "#e53e3e",
      600: "#c53030",
      700: "#9b2c2c",
      800: "#822727",
      900: "#63171b",
    },
    orange: {
      50: "#fffaf0",
      100: "#fff3dc",
      200: "#feb2b2",
      300: "#fc8181",
      400: "#f56565",
      500: "#c53030",
      600: "#9b2c2c",
      700: "#9b2c2c",
      800: "#822727",
      900: "#63171b",
    },
    yellow: {
      50: "#FFFFF0",
      100: "#FEFDCB",
      200: "#FAF089",
      300: "#F6E05E",
      400: "#ECC94B",
      500: "#D69E2E",
      600: "#B7791F",
      700: "#975A16",
      800: "#744210",
      900: "#5F370E",
    },
    green: {
      50: "#F0FFF4",
      100: "#D1F8DD",
      200: "#9AE6B4",
      300: "#68D391",
      400: "#48BB78",
      500: "#38A169",
      600: "#2F855A",
      700: "#276749",
      800: "#22543D",
      900: "#1C4532",
    },
    teal: {
      50: "#e6fffa",
      100: "#cdfaf3",
      200: "#83e6db",
      300: "#51d2c8",
      400: "#35b3b0",
      500: "#02999A",
      600: "#297c7f",
      700: "#266065",
      800: "#215055",
      900: "#1c4146",
    },
    cyan: {
      50: "#EDFDFD",
      100: "#CEF4FA",
      200: "#9DECF9",
      300: "#76E4F7",
      400: "#0BC5EA",
      500: "#00B5D8",
      600: "#00A3C4",
      700: "#0987A0",
      800: "#086F83",
      900: "#065666",
    },
    blue: {
      50: "#ebf8ff",
      100: "#D5EEFC",
      200: "#90cdf4",
      300: "#63b3ed",
      400: "#4299e1",
      500: "#3182ce",
      600: "#2b6cb0",
      700: "#2c5282",
      800: "#2a4365",
      900: "#1A365D",
    },
    purple: {
      50: "#f6f6ff",
      100: "#e3e0ff",
      200: "#c0baff",
      300: "#9a90fd",
      400: "#7c72f7",
      500: "#5c50e7",
      600: "#4a37d5",
      700: "#3b2ca9",
      800: "#312686",
      900: "#201d67",
    },
    pink: {
      50: "#FFF5F7",
      100: "#FFF5F7",
      200: "#FBB6CE",
      300: "#F687B3",
      400: "#ED64A6",
      500: "#D53F8C",
      600: "#B83280",
      700: "#97266D",
      800: "#702459",
      900: "#521B41",
    },
    white: "#ffffff",
  },
};

const colors = {
  primary: basePalette.colors.purple,
  success: basePalette.colors.teal,
  ...basePalette.colors,
};

export default colors;