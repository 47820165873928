const breakpoints = {
  // Chakra default values:
  // const breakpoints = {
  //     base: "0em",
  //     sm: "30em",
  //     md: "48em",
  //     lg: "62em",
  //     xl: "80em",
  //     "2xl": "96em",
  //   }
};
export default breakpoints;
